// Firebase console -> Project settings -> Your apps
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Change this for each new project
const firebaseConfig = {
	apiKey: "AIzaSyCvDokwcUtZN5uYp8CSToF0zQrtvEteUJw",
	authDomain: "retroeasy-13da8.firebaseapp.com",
	projectId: "retroeasy-13da8",
	storageBucket: "retroeasy-13da8.appspot.com",
	messagingSenderId: "593644237491",
	appId: "1:593644237491:web:5d46bd3d45db37084b76c6",
	measurementId: "G-7ED4X0X9V7"
};

// Initialize Firebase
/* eslint-disable no-unused-vars */
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
/* eslint-enable no-unused-vars */
