import { useState } from "react";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../utils/Firebase';

// https://stackoverflow.com/a/65211509/4191951
function useEmailSubscriptionForm() {
  const [inputEmail, setInputEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSuccess(false);

    addDoc(collection(db, 'subscribers'), {
      email: inputEmail,
      timestamp: serverTimestamp()
    }).then((data) => {
      setSuccess(true);
      setInputEmail('');
    });
  }

  // https://rangle.io/blog/simplifying-controlled-inputs-with-hooks
  return {
    bind: {
      value: inputEmail,
      onChange: event => {
        setInputEmail(event.target.value);
      }
    },
    success,
    handleSubmit
  };
}

export default useEmailSubscriptionForm;
